import DateFnsUtils from "@date-io/date-fns"
import { Button } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import Grid from "@material-ui/core/Grid"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Modal from "@material-ui/core/Modal"
import Paper from "@material-ui/core/Paper"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import type { Moment } from "moment"
import { type ChangeEvent, Fragment, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import {
	getIepList,
	getSettings,
	getSimpleInvoiceRecords,
	validateBulkRecords,
	uploadInvoice,
	uploadCustomerRules,
	uploadPools,
} from "../api"
import Layout from "../components/layout/Layout"
import UploadComp from "../components/upload/UploadComponent"
import { authStore } from "../stores/authStore"
import { toastStore } from "../stores/toastStore"

const moment = require("moment")

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(3),
		},
		wrapper: {
			width: "100%",
			height: "100%",
		},
		inputWrapper: {
			position: "absolute",
			overflow: "hidden",
			display: "inline",
			width: "100%",
			height: "50%",
			zIndex: 1,
			opacity: 0,
			left: 0,
			top: 0,
		},
		paper: {
			position: "absolute",
			width: 500,
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
)

function rand() {
	return Math.round(Math.random() * 15) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()
	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	}
}

interface Props {
	style: any
	match: any
}

export default function (props: Props) {
	const classes = useStyles()
	const [modalStyle] = useState(getModalStyle)
	const [importFileType, setImportFileType] = useState<string>(props.match.path.includes("manager") ? "cr" : "iep")
	const [isManager, setIsManager] = useState<boolean>(props.match.path.includes("manager"))
	const [fileValue, setFileValue] = useState<any>()
	const inputLabel = useRef<HTMLLabelElement>(null)
	const [iepSelected, setIepSelected] = useState(-1)
	const [invoiceNum, setInvoiceNum] = useState<string>("")
	const [iepList, setIepList] = useState<any | undefined>()
	const [daysOffset, setDaysOffset] = useState<number>(2)
	const [open, setOpen] = useState(false)
	const [textWhileWait, setTextWhileWait] = useState("")
	const [labelWidth, setLabelWidth] = useState(0)
	const [selectedDate, setSelectedDate] = useState<Moment | null>(moment())
	const [clearFile, setClearFile] = useState(false)

	const auth = authStore.use()
	const me = auth.me
	const history = useHistory()

	useEffect(() => {
		if (!isManager) {
			getIepList(setIepList)
			getSettings((settings) => {
				settings.forEach((setting) => {
					if (setting.name == "DaysOffsetForValidate") {
						setDaysOffset(Number.parseInt(setting.value))
					}
				})
			})
		}
	}, [isManager])

	useEffect(() => {
		setLabelWidth(inputLabel ? inputLabel.current!.offsetWidth : 0)
	}, [inputLabel])

	useEffect(() => {
		if (props.match.path.includes("manager")) {
			setImportFileType("cr")
			setIsManager(true)
		}
		if (!props.match.path.includes("manager")) {
			setImportFileType("iep")
			setIsManager(false)
		}
		// navigating directly between manager and non-manager pages doesn't trigger a re-render
		// so we need monitor the path and update the state accordingly
		// TODO: we should probably refactor the 'uploadManager' and 'upload' pages to be separate components
	}, [props.match.path])

	const clearUpload = () => {
		setClearFile(true)
		setFileValue(undefined)
		setInvoiceNum("")
		setIepSelected(-1)
		setSelectedDate(moment())
		setClearFile(false)
	}

	const iepUpload = async (formData: FormData) => {
		const invoiceID = await uploadInvoice(formData).then((data) => { return data.invoice_id })
		setTextWhileWait("Sending Invoice Records for Validation")
		const recordIds: Array<number> = await getSimpleInvoiceRecords(invoiceID)
		if (recordIds.length > 0) {
			setTextWhileWait("Starting Validation of Invoice Records: " + recordIds.length)
			console.log("Reticulating Splines: " + recordIds.length)
			const revalidate = false
			validateBulkRecords(recordIds, daysOffset || 2, revalidate).then(() => {
				clearUpload()
				//set a 1.5 second delay to allow the user to see the TextWhileWait message and make it clear that the upload is being processed
				setTimeout(() => {
					// we want them to have to manually close this toast
					// 12 hours = 43200000
					const allowClickAway = false
					toastStore.showToast("success", "Invoice validation started. You will be notified when it has completed.", 43200000, allowClickAway)
					setOpen(false)
				}, 1500)
			}).catch(() => {
				// show this toast for a long time as the user is probably not looking at this tab
				toastStore.showToast("error", "File was uploaded but the validation call timed out. The system will continue to process in the background.", 43200000)
				setOpen(false)
			}
			)
		}
	}

	const handleDateChange = (date: Moment | null) => {
		setSelectedDate(date)
	}

	const handleInvoiceNumChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInvoiceNum((event.target as HTMLInputElement).value)
	}

	const handlefileTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
		setImportFileType((event.target as HTMLInputElement).value)
	}

	const handleIEPDDChange = (event: ChangeEvent<{ value: unknown }>) => {
		setIepSelected(event.target.value as number)
	}

	const handleDaysChange = (event: any) => {
		event.target.value = event.target.value.match(/[0-9]+/)
		setDaysOffset(event.target.value)
	}

	const handleProcessClick = () => {
		if (
			fileValue &&
			((importFileType !== "iep" || iepSelected && invoiceNum))
		) {
			const formData = new FormData()
			const choosenDt = selectedDate ? moment.utc(selectedDate) : moment()
			var docDate = choosenDt.format("L")
			const additdata = `{"username":"${me?.username}", "docDT":"${docDate}", "iepID":${iepSelected}, "invoiceNum":"${invoiceNum}"}`

			formData.append("data", additdata)
			formData.append("file", fileValue)
			setOpen(true)
			setTextWhileWait("...Uploading File")

			const handleError = (err) => {
				setOpen(false)
				toastStore.showToast("error", err.message)
			}
			const handleSuccess = () => {
				setTextWhileWait("DONE! ")
				toastStore.showToast("success", "File uploaded successfully")
				setOpen(false)
				history.push("/dashboard")
			}

			if (importFileType === "iep") {
				iepUpload(formData)
			} else if (importFileType === "cr") {
				uploadCustomerRules(formData)
					.then(handleSuccess)
					.catch(handleError)
			} else if (importFileType === "pool") {
				uploadPools(formData)
					.then(handleSuccess)
					.catch(handleError)
			} else {
				handleError(new Error("Unknown file type, please select a file type"))
			}
		}
	}

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={open}>
					<div style={modalStyle} className={classes.paper}>
						{textWhileWait}
					</div>
				</Modal>
				<Box style={{ padding: 30 }}>
					<Grid
						container
						style={{
							width: "auto",
							margin: "0% 20%",
						}}>
						<Grid item sm={12} xs={12} style={{ padding: 30 }}>
							<Box justifyContent="center" display="flex">
								UPLOAD FILES
							</Box>
						</Grid>
						<Grid item sm={12}>
							<Grid container style={{ padding: 30 }}>
								<Grid item sm={4}>
									<FormControl component="fieldset">
										<FormLabel component="legend">Select file type</FormLabel>
										<RadioGroup
											aria-label="file"
											name="file2"
											value={importFileType}
											onChange={handlefileTypeChange}>
											{isManager ? (
												<Fragment>
													<FormControlLabel
														value="cr"
														control={<Radio style={{ color: "#707070" }} />}
														label="Customer Rules"
														labelPlacement="end"
													/>
													<FormControlLabel
														value="pool"
														control={<Radio style={{ color: "#707070" }} />}
														label="Pools"
														labelPlacement="end"
													/>
												</Fragment>
											) : (
												<Fragment>
													<FormControlLabel
														value="iep"
														control={<Radio style={{ color: "#707070" }} />}
														label="Invoice"
														labelPlacement="end"
													/>
												</Fragment>
											)}
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item sm={8}>
									<UploadComp setFile={setFileValue} clearFile={clearFile} />
									{!isManager && (
										<Fragment>
											<TextField
												style={{ marginTop: 10, width: "100%" }}
												label="Days for Offset"
												variant="outlined"
												value={daysOffset}
												onChange={handleDaysChange}
											/>
										</Fragment>
									)}
								</Grid>
							</Grid>
						</Grid>
						{!isManager && importFileType === "iep" && (
							<Grid item sm={12}>
								<Grid container>
									<Grid item sm={6} style={{ padding: 15 }}>
										<FormControl variant="outlined" style={{ width: "100%" }}>
											<InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
												Select IEP
											</InputLabel>
											<Select
												labelId="demo-simple-select-outlined-label"
												id="demo-simple-select-outlined"
												onChange={handleIEPDDChange}
												labelWidth={labelWidth}
												value={iepSelected}>
												{iepList &&
													iepList.map((i: any) => {
														return <MenuItem value={i.id_}>{i.abbrv}</MenuItem>
													})}
											</Select>
										</FormControl>
									</Grid>
									<Grid item sm={6} style={{ padding: 15 }}>
										<TextField
											id="outlined-basic"
											label="Invoice #"
											variant="outlined"
											style={{ width: "100%" }}
											value={invoiceNum}
											onChange={handleInvoiceNumChange}
										/>
									</Grid>
									<Grid item sm={6}></Grid>
									<Grid item sm={6} style={{ padding: 15 }}>
										<MuiPickersUtilsProvider utils={DateFnsUtils}>
											<KeyboardDatePicker
												disableToolbar
												variant="inline"
												format="MM/dd/yyyy"
												margin="normal"
												id="date-picker-inline"
												label="Date invoice received"
												value={selectedDate}
												style={{ width: "100%" }}
												onChange={handleDateChange}
												KeyboardButtonProps={{
													"aria-label": "change date",
												}}
											/>
										</MuiPickersUtilsProvider>
										<Box>Today's Date: {moment().format("L")}</Box>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item sm={12}>
							<Box justifyContent="center" display="flex" style={{ margin: 30 }}>
								<Button
									variant="contained"
									color="primary"
									size="medium"
									disabled={fileValue === undefined || importFileType === undefined}
									onClick={handleProcessClick}>
									Process
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
