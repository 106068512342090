import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { getRoles } from "../api"
import { getUser, setUserAPI } from "../api/userAPI"
import { isEmailValid } from "../lib/util"
import WaitPopup from "./WaitPopup"

interface Props {
	id: number
	handleClose: Function
	newState: boolean
}

export default function (props: Props) {
	const [user, setUser] = React.useState<any | undefined>()
	const [roles, setRoles] = React.useState<any | undefined>()
	const [name, setName] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [username, setUsername] = React.useState("")
	const [pass, setPass] = React.useState(null)
	const [role, setRole] = React.useState(-1)

	let openWait = true

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (user) {
			setName(user.name)
			setEmail(user.email)
			setUsername(user.username)
			setPass(user.password)
			setRole(user.role_id)
		}
	}, [user])

	function loadPageInfo() {
		if (props.newState) {
			setUser({ name: "", email: "", role_id: -1, username: "" })
		} else {
			getUser(props.id, setUser)
		}
		getRoles(setRoles)
	}

	function handleRoleDDChange(event: any) {
		setRole(event.target.value)
	}

	function handleSave() {
		if (validated()) {
			user.name = name
			user.username = username
			user.password = pass ?? null
			user.email = email
			user.role_id = role
			setUserAPI(user, props.handleClose)
		} else {
			alert("Information is not complete or in the correct format.")
		}
	}

	function validated() {
		if (name && username && isEmailValid(email) && role) {
			if (!props.newState) {
				return true
			}
			return !!pass
		}
		return false
	}

	if (user) {
		openWait = false
	}

	return (
		<div>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Grid container>
				<Grid item sm={12}>
					<Box>{props.newState ? <h3>Add User</h3> : <h3>Edit User</h3>}</Box>
				</Grid>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="NAME"
								value={name}
								onChange={(event: any) => {
									setName(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={6} style={{ margin: 10 }}>
							<TextField
								error={isEmailValid(email)}
								label="EMAIL"
								type="email"
								value={email}
								onChange={(event: any) => {
									setEmail(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={3} style={{ margin: 10 }}>
							<TextField
								size="small"
								select
								variant="outlined"
								label="ROLE"
								value={role}
								onChange={handleRoleDDChange}>
								{roles?.map((item) => {
									return (
										<MenuItem key={item.id_} value={item.id_}>
											{item.role}
										</MenuItem>
									)
								})}
							</TextField>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="USERNAME"
								value={username}
								onChange={(event: any) => {
									setUsername(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={6} style={{ margin: 10 }}>
							<TextField
								label={props.newState ? "PASSWORD" : "RESET PASSWORD"}
								onChange={(event: any) => {
									setPass(event.target.value)
								}}
								variant="outlined"
								type="password"
							/>
						</Grid>
						<Grid item sm={12}>
							<Box style={{ float: "right" }}>
								<Button
									style={{ margin: 5 }}
									onClick={() => {
										props.handleClose()
									}}
									variant="outlined"
									color="primary">
									Close
								</Button>
								{/* TODO: disable if entry is invalid or incomplete */}
								<Button
									style={{ margin: 5 }}
									onClick={handleSave}
									variant="outlined"
									color="primary">
									Save
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
