import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { useEffect, useState } from "react"
import IEPManager from "../components/CustomTable/iepManagerTable/IEPManager"
import Layout from "../components/layout/Layout"
import { getIepList, getArchivedIepList } from "../api"
import type { Module } from "../types/Module"

export default function () {
	const [iepList, setIepList] = useState<Module[]>([])
	const [archivedIepList, setArchivedIepList] = useState<Module[]>([])

	useEffect(() => {
		loadPageInfo()
	}, [])

	async function loadPageInfo() {
		getIepList(setIepList)
		setArchivedIepList(await getArchivedIepList())
	}

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<Box style={{ padding: 30 }}>
					<Grid container style={{ overflow: "hidden", width: "100%" }}>
						<Box>
							<h3>MANAGE IEPs</h3>
						</Box>
						<Grid item sm={12}>
							<h4>ACTIVE IEPs</h4>
							<IEPManager ieps={iepList} refreshData={loadPageInfo} />
						</Grid>
						<Grid item sm={12}>
							<h4 style={{ paddingTop: 30 }}>ARCHIVED IEPs</h4>
							<IEPManager ieps={archivedIepList} refreshData={loadPageInfo} />
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}
