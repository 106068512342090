export interface DataWorkflow {
	invoice_dt: Date | null
	id_: number
	chassis: string
	container1: string
	dt_out: Date | null
	dt_in: Date | null
	invoice_ssl: string
	out_facility: string
	in_facility: string
	billed_days_out: string
	total_billed: string
	invoice_area: string
	iep_pool: string
	manifest_num: string
	manifest: string
	manifest_ssl: string
	terminal_name: string
	customer_name: string
	booking_bol: string
	total_rebill: string
	is_duplicate: boolean
	updated_dt: Date
	invoice_num: string
	credit_amt: string
	profit_loss: string
	dispute_reason: string
}

export const HeaderDataInvoiceRecons: HeadCell[] = [
	{ id: "id_", numeric: true, disablePadding: false, label: "ID" },
	{ id: "dispute_reason", numeric: false, disablePadding: false, label: "REASON" },
	{ id: "chassis", numeric: false, disablePadding: false, label: "CHASSIS" },
	{
		id: "container1",
		numeric: false,
		disablePadding: false,
		label: "CONTAINER",
	},
	{ id: "dt_out", numeric: false, disablePadding: false, label: "DATE OUT" },
	{ id: "dt_in", numeric: false, disablePadding: false, label: "DATE IN" },
	{
		id: "out_facility",
		numeric: false,
		disablePadding: false,
		label: "OUT FACILITY",
	},
	{
		id: "in_facility",
		numeric: false,
		disablePadding: false,
		label: "IN FACILITY",
	},
	{
		id: "billed_days_out",
		numeric: false,
		disablePadding: false,
		label: "BILLED DAYS OUT",
	},
	{
		id: "total_billed",
		numeric: false,
		disablePadding: false,
		label: "TOTAL BILLED",
	},
	{ id: "iep_pool", numeric: false, disablePadding: false, label: "POOL" },
	{ id: "manifest_num", numeric: false, disablePadding: false, label: "MANIFEST" },
	{ id: "invoice_ssl", numeric: false, disablePadding: false, label: "MANIFEST SSL" },
	{ id: "terminal_name", numeric: false, disablePadding: false, label: "TERMINAL" },
	{ id: "customer_name", numeric: false, disablePadding: false, label: "BILL TO" },
	{ id: "total_rebill", numeric: true, disablePadding: false, label: "REBILL" },
	{ id: "profit_loss", numeric: true, disablePadding: false, label: "PROFIT/LOSS" },
	{ id: "credit_amt", numeric: false, disablePadding: false, label: "CREDIT" },
	{ id: "is_duplicate", numeric: false, disablePadding: false, label: "Is Duplicate" },
	{ id: "invoice_num", numeric: false, disablePadding: false, label: "INVOICE" },
	{ id: "invoice_dt", numeric: false, disablePadding: false, label: "INVOICE DATE" },
]

export interface HeadCell {
	disablePadding: boolean
	id: keyof DataWorkflow
	label: string
	numeric: boolean
}
