import Box from "@material-ui/core/Box"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import IconButton from "@material-ui/core/IconButton"
import type { PaperProps } from "@material-ui/core/Paper"
import Popover from "@material-ui/core/Popover"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import LaunchIcon from "@material-ui/icons/Launch"
import React from "react"
import { type Order, getComparator } from "../../../lib/sort"
import TableToExcel from "../../TableToExcel/TableToExcel"
import { useStyles } from "./styles"
import { type DataInvoiceWorkflow, type HeadCell, HeaderDataInvoiceRecons } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataInvoiceWorkflow) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
	viewTrinium?: boolean
	viewReason?: boolean
	viewReview?: boolean
	viewPopOver?: boolean
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort, headCells, viewPopOver } =
		props
	const createSortHandler =
		(property: keyof DataInvoiceWorkflow) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				{viewPopOver && (
					<TableCell
						padding={"checkbox"}
						style={{
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						{"..."}
					</TableCell>
				)}

				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	let first: any = a[orderBy]
	let second: any = b[orderBy]
	const parsedA = Number.parseInt(first)
	const parsedB = Number.parseInt(second)

	if (!isNaN(parsedA) && !isNaN(parsedB)) {
		first = parsedA
		second = parsedB
	}

	if (second < first) {
		return -1
	}
	if (second > first) {
		return 1
	}

	return 0
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	if (array.length > 0) {
		const stabilizedThis = array.map((el, index) => [el, index] as [T, number])

		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0])
			if (order !== 0) return order
			return a[1] - b[1]
		})

		return stabilizedThis.map((el) => el[0])
	} else {
		return array
	}
}

function rand() {
	return Math.round(Math.random() * 10) - 10
}

function getModalStyle() {
	const top = 50 + rand()
	const left = 50 + rand()

	return {
		top: `${top}%`,
		left: `${left}%`,
		minWith: 1100,
		transform: `translate(-${top}%, -${left}%)`,
	}
}

interface Props {
	invoiceData: any[]
	setInvoiceData?: Function
	viewPopOver?: boolean
	disableControls?: boolean
	maxHeight?: number
	PopoverControl?: Function
}

export default function (props: Props) {
	const classes = useStyles()
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<string>("id_")
	const [selectedItem, setSelected] = React.useState<number>()
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(50)
	const [openModal, setOpenModal] = React.useState(false)
	const [invoiceRecordID, setInvoiceRecordID] = React.useState(false)
	const [invoices, setInvoices] = React.useState<any | undefined>()

	React.useEffect(() => {
		if (props.invoiceData) {
			setInvoices(props.invoiceData)
		}
	}, [props.invoiceData])

	React.useEffect(() => {
		if (invoices) {
			props.setInvoiceData(invoices)
		}
	}, [invoices])

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof DataInvoiceWorkflow,
	) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
		let newSelected = -1
		newSelected = name
		setSelected(newSelected)
	}

	const handleDoubleClick = (event: any, name: number, id: number) => {
		setAnchorEl(event.currentTarget)
	}

	const handlSearchClick = (event: any, name: number, id: number) => {
		handleOpen()
	}

	const handlePopupClose = () => {
		setAnchorEl(null)
	}

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(Number.parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleOpen = () => {
		setOpenModal(true)
	}

	const handleClose = () => {
		setOpenModal(false)
	}

	const handleCheckChange = (event: any, recID: number) => {
		const rows = invoices
		rows.forEach((data) => {
			if (data.id_ == recID) {
				data.checked = !data.checked
			}
		})
		setInvoices(rows)
	}

	const PaperProps: PaperProps = {
		square: true,
		style: { width: "auto" },
		elevation: 50,
		id: "thiisid",
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined

	const maxHeight = props.maxHeight ? props.maxHeight.toString() + "px" : "100%"
	if (props.invoiceData && props.invoiceData.length > 0) {
		return (
			<div id="invoicePanel" style={{ height: "100%", width: "100%" }}>
				<Box style={{ height: "auto", width: "100%", padding: 0 }}>
					<TableToExcel
						id="invoice_write_off_table"
						sheet="Worksheet"
						table="invoiceRec"
						fileName={"Invoice_Rec_" + Date.now()}
						buttonText=""
						className=""></TableToExcel>
				</Box>
				<TableContainer style={{ maxHeight: maxHeight, width: "100%" }}>
					<Table id="invoiceRec" stickyHeader={true} size={"small"}>
						<SortableTableHeaders
							classes={classes}
							numSelected={1}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={props.invoiceData.length}
							headCells={HeaderDataInvoiceRecons}
							viewPopOver={props.viewPopOver}
						/>
						<TableBody>
							{invoices &&
								stableSort(invoices, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										return (
											<TableRow
												key={row.id_}
												style={{
													textAlign: "center",
												}}
												hover
												onClick={(event) => handleClick(event, index)}
												tabIndex={-1}
												selected={selectedItem === index}>
												{props.viewPopOver && (
													<TableCell style={{ padding: 1 }}>
														<IconButton
															onClick={(event) => handleDoubleClick(event, index, row.id_)}>
															<LaunchIcon />
														</IconButton>
													</TableCell>
												)}
												<TableCell style={{ padding: "2 0" }}>
													<FormControlLabel
														control={
															<Checkbox
																checked={row.checked}
																onChange={(event) => {
																	handleCheckChange(event, row.id_)
																}}
																color="primary"
															/>
														}
														label=""
													/>
												</TableCell>
												<TableCell style={{ padding: 1 }}>{row.id_}</TableCell>
												<TableCell style={{ padding: 1 }}>
													{row.iep_name ? row.iep_name.toUpperCase() : ""}
												</TableCell>
												<TableCell style={{ padding: 1 }}>
													{row.name ? row.name.toUpperCase() : ""}
												</TableCell>
												<TableCell style={{ padding: 1 }}>
													{row.invoice_num ? row.invoice_num.toUpperCase() : ""}
												</TableCell>
												<TableCell style={{ padding: 1 }}>
													{row.documented_dt ? row.documented_dt.format("L") : ""}
												</TableCell>
												<TableCell style={{ padding: 1 }}>
													{row.entered_dt ? row.entered_dt.format("L") : ""}
												</TableCell>
											</TableRow>
										)
									})}
						</TableBody>
					</Table>
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						PaperProps={{ style: { minWidth: 1200 } }}
						onClose={handlePopupClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "left",
						}}>
						{props.PopoverControl && props.PopoverControl(invoiceRecordID)}
					</Popover>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[100]}
					component="div"
					count={props.invoiceData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
				/>
			</div>
		)
	} else {
		return (
			<div>
				<h3>No Records</h3>
			</div>
		)
	}
}
