import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles"

import { useState, useEffect, ChangeEvent } from "react"
import { type RouteProps, useHistory, useParams } from "react-router-dom"

import {
	createDisputeHistory,
	getInvoice,
	getSettings,
	getSimpleInvoiceRecords,
	getStatusListByCategory,
	validateBulkRecords,
	unlockInvoice,
} from "../api"
import { getDisputeReasons } from "../api/disputeReasonAPI"
import { getInvoiceReconciliationsByID } from "../api/invoiceReconciliationsAPI"
import InvoiceNeedsReview from "../components/CustomTable/invoiceWorkflowTable/InvoiceNeedsReview"
import InvoiceRecordApprovalCriteria from "../components/InvoiceRecordApprovalCriteria"
import { TabPanel } from "../components/TabPanel"
import WaitPopup from "../components/WaitPopup"
import Layout from "../components/layout/Layout"
import { a11yTabNames } from "../lib/a11y"
import { formatDateNoTimezoneMMDDYYYY } from "../lib/formatters"
import { descendingComparator } from "../lib/sort"
import { authStore } from "../stores/authStore"
import { toastStore } from "../stores/toastStore"
import type { InvoiceDTO } from "../types/Invoice"
import type { DisputeReasonDTO } from "../types/tables/DisputeReason"
import type { IEPInvoicePlus, getInvoiceReconciliationsDTO } from "../types/tables/IEPRecord"
import type { RecordStatusDTO } from "../types/tables/RecordStatus"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: "absolute",
			width: "90%",
			maxHeight: "80%",
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		modal: {
			position: "absolute",
			width: "90%",
			maxHeight: "80%",
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		button: {
			margin: theme.spacing(1),
		},
	}),
)

export default function InvoiceWorkflowPage(props: RouteProps) {
	const classes = useStyles()
	const [selectedTab, setSelectedTab] = useState(0)
	const [invoiceReconciliations, setInvoiceReconciliations] = useState<getInvoiceReconciliationsDTO[]>()
	const [pendingFilteredRecs, setPendingFilteredRecs] = useState<IEPInvoicePlus[]>([])
	const [approvedFilteredRecs, setApprovedFilteredRecs] = useState<IEPInvoicePlus[]>([])
	const [disputedRecs, setDisputedRecs] = useState<IEPInvoicePlus[]>([])
	const [disputedFilteredRecs, setDisputedFilteredRecs] = useState<IEPInvoicePlus[]>([])
	const [creditedRecs, setCreditedRecs] = useState<IEPInvoicePlus[]>([])
	const [invoice, setInvoice] = useState<InvoiceDTO>()
	const [statusList, setStatusList] = useState<RecordStatusDTO[]>()
	const [disputeReasons, setDisputeReasons] = useState<DisputeReasonDTO[]>()
	const [recordFound, setRecordFound] = useState<boolean>(false)
	const [searchRecordId, setSearchRecordId] = useState<string | undefined>(undefined)
	const { id: invoiceID } = useParams<{ id: string }>()
	const [openWait, setOpenWait] = useState<boolean>(true)
	const [textWhileWait, setTextWhileWait] = useState("...Loading Info")
	const [disableChanges, setDisableChanges] = useState<boolean>(false)

	const auth = authStore.use()
	const me = auth.me
	const history = useHistory()
	const tabIndexes = {
		REVIEW: 0,
		APPROVED: 1,
		DISPUTED: 2,
		CREDITED: 3
	}


	useEffect(() => {
		setOpenWait(true)
		getAndSetSearchRecordId()
		loadPageInfo()
	}, [invoiceID])


	useEffect(() => {
		let timeout = undefined
		if (invoice) {
			// changes are disabled for invoices that are in validation or have been completed and not unlocked
			setDisableChanges(invoice.in_validation || (invoice.is_completed && !invoice.ignore_update_dt))
			if (invoice.in_validation) {
				// wait one minute, then reload to check if it is still in validation
				timeout = setTimeout(() => {
					loadPageInfo()
				}, 60000)
			}
		}
		return () => {
			if (timeout) {
				clearTimeout(timeout)
			}
		}
	}, [invoice])


	useEffect(() => {
		if (invoiceReconciliations) {
			filterInvoiceRecords(invoiceReconciliations)
		}
	}, [invoiceReconciliations])


	useEffect(() => {
		const checkRecordsForRecId = (recs: IEPInvoicePlus[]) => {
			return recs.some((rec) => rec.id_.toString() === searchRecordId)
		}

		if (checkRecordsForRecId(pendingFilteredRecs)) {
			handleRecordIdFound(tabIndexes.REVIEW)
		} else if (checkRecordsForRecId(approvedFilteredRecs)) {
			handleRecordIdFound(tabIndexes.APPROVED)
		} else if (checkRecordsForRecId(disputedFilteredRecs)) {
			handleRecordIdFound(tabIndexes.DISPUTED)
		} else if (checkRecordsForRecId(creditedRecs)) {
			handleRecordIdFound(tabIndexes.CREDITED)
		}
	}, [searchRecordId, pendingFilteredRecs, approvedFilteredRecs, disputedFilteredRecs, creditedRecs])



	useEffect(() => {
		if (disputedRecs) {
			for (const rec of disputedRecs) {
				if (rec.matches && rec.matches.length > 0) {
					rec.booking_bol = rec.matches[0].booking_bol
					rec.ar_chuse = rec.matches[0].ar_chuse?.toString() ?? rec.matches[0].ar_trx?.toString()
					rec.customer_name = rec.matches[0].customer_name

					if (rec.matches.length > 1) {
						rec.manifest = `'${rec.manifest_num}`
					} else {
						rec.manifest = rec.manifest_num
					}
				}
			}
			setDisputedFilteredRecs(disputedRecs)
		}
	}, [disputedRecs])


	const handleRecordIdFound = (foundTab: number) => {
		setRecordFound(true)
		setSelectedTab(foundTab)
	}

	const getAndSetSearchRecordId = () => {
		const query = new URLSearchParams(props.location.search)
		const recordId = query.get("record")?.toString()
		if (recordId) {
			setSearchRecordId(recordId)
		}
	}

	const clearSearchedRecord = () => {
		history.push(props.location.pathname)
		setRecordFound(false)
		setSearchRecordId(undefined)
	}

	const handleChange = (_: ChangeEvent<unknown>, newValue: number) => {
		setSelectedTab(newValue)
		clearSearchedRecord()
		setOpenWait(true)
		loadPageInfo()
	}

	const handleReValidate = async () => {
		setOpenWait(true)
		setTextWhileWait("...Validating Invoice Records")
		const invoiceRecordIds = await getSimpleInvoiceRecords(invoiceID)
		if (!invoiceRecordIds || invoiceRecordIds.length === 0) {
			setTextWhileWait("No records to validate")
			setTimeout(() => {
				setOpenWait(false)
				setTextWhileWait("...Loading Info")
			}, 2000)
			return
		}
		setTextWhileWait(`...Validating Invoice Records: ${invoiceRecordIds.length}`)
		let daysOffset = undefined
		await getSettings((settings) => {
			if (settings) {
				for (const item of settings) {
					if (item.name === "DaysOffsetForValidate") {
						daysOffset = item.value
					}
				}
			}
		})
		const revalidate = true
		validateBulkRecords(invoiceRecordIds, daysOffset, revalidate)
			.then(() => {
				// we want them to have to manually close this toast
				// 12 hours = 43200000
				const allowClickAway = false
				toastStore.showToast("success", "Invoice validation started. You will be notified when it has completed.", 43200000, allowClickAway)
				loadPageInfo()
			})
			.catch(() => {
				toastStore.showToast("error", "Error validating invoice records")
				setOpenWait(false)
				setTextWhileWait("...Loading Info")
			})
	}


	const handleUnlock = () => {
		unlockInvoice(invoiceID, me.username, (data) => {
			if (data.unlock === true) {
				loadPageInfo()
			} else {
				setOpenWait(true)
				setTextWhileWait("Can NOT unlock invoice. Invoice has an AP record.")
			}
		})
	}

	const handleReviewClick = () => {
		createDisputeHistory(invoiceID, me.username, () => {
			history.push(`/invoicereviewworkflow/${invoice ? invoice.id_ : -1}`)
		})
	}


	const popoverInvoiceRecordApprovalDetails = (id: number, disableControls: boolean) => {
		return <InvoiceRecordApprovalCriteria invoiceRecordID={id} disableControls={disableControls} />
	}


	const sortAndSetDisputeReasons = (disputeReasons: DisputeReasonDTO[]) => {
		const sortedDisputeReasons = disputeReasons.sort((a, b) => {
			return -descendingComparator(a, b, "reason")
		})
		setDisputeReasons(sortedDisputeReasons)
	}


	const loadPageInfo = () => {
		setTextWhileWait("...Loading Info")
		setOpenWait(true)
		if (!statusList) {
			getStatusListByCategory("invoice", setStatusList)
		}
		if (!disputeReasons) {
			getDisputeReasons(sortAndSetDisputeReasons)
		}
		getInvoice(+invoiceID, setInvoice)

		getInvoiceReconciliationsByID(invoiceID).then((data) => {
			if (data) {
				setInvoiceReconciliations(data)
			}
			else {
				setOpenWait(false)
			}
		})
	}


	const filterInvoiceRecords = (invoiceRecords: getInvoiceReconciliationsDTO[]) => {
		// Invoice Reconciliations Approved
		const invoiceReconciliationsApproved = (invoiceRecords).filter(
			(item) => item.record_status_name === "APPROVED",
		)
		const approvedRecsPluss = addPropertiesToArray(invoiceReconciliationsApproved)
		setApprovedFilteredRecs(approvedRecsPluss)

		// Invoice Reconciliations Disputed and Credited
		const invoiceReconciliationsDisputed = (invoiceRecords).filter(
			(item) => item.record_status_name === "DISPUTED"
		)
		const dispCreditRecsPluss = addPropertiesToArray(invoiceReconciliationsDisputed)

		const tempCredited = []
		const tempDisputed = []

		for (const rec of dispCreditRecsPluss) {
			if (rec.dispute_transactions && rec.dispute_transactions.length > 0) {
				const disputeForRecord = rec.dispute_transactions.find(
					(d) => d.invoice_record_id === rec.id_ && (d.credit_amt ?? 0) > 0,
				)
				if (disputeForRecord) {
					tempCredited.push(rec)
				} else {
					tempDisputed.push(rec)
				}
			}
		}

		setDisputedRecs(tempDisputed)
		setCreditedRecs(tempCredited)

		// Pending Recs
		const invoiceReconciliationsPending = (invoiceRecords).filter(
			(item) => item.record_status_name === "REVIEW",
		)
		const pendingRecsPluss = addPropertiesToArray(invoiceReconciliationsPending)
		setPendingFilteredRecs(pendingRecsPluss)
		setOpenWait(false)
	}


	const addPropertiesToArray = (recs: IEPInvoicePlus[]) => {
		for (const rec of recs) {
			if (rec.matches?.length) {
				rec.booking_bol = rec.matches[0].booking_bol
				rec.ar_chuse = rec.matches[0].ar_chuse?.toString() ?? rec.matches[0].ar_trx?.toString()
				rec.customer_name = rec.matches[0].customer_name

				if (rec.matches.length > 1) {
					rec.manifest = `'${rec.manifest_num}`
				} else {
					rec.manifest = rec.manifest_num
				}
			}
		}

		return recs
	}


	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<WaitPopup
					open={openWait}
					closeOnRequest={() => {
						setOpenWait(false)
					}}>
					{textWhileWait}
				</WaitPopup>
				{/* This popup should only show up if a user manual changes the record query in the URL */}
				<WaitPopup
					open={searchRecordId && !recordFound && !openWait}
					closeOnRequest={() => {
						clearSearchedRecord()
					}}>
					Record not found in this invoice
				</WaitPopup>
				{invoice && Object.keys(invoice).length ? (
					<Box style={{ padding: 30 }}>
						<Grid container style={{ overflow: "hidden", width: "100%" }}>
							<Grid item sm={12}>
								{invoice.in_validation && (
									<Box style={{ backgroundColor: "#ffcccb", padding: 10, paddingLeft: 20 }}>
										<p>
											<b>Locked for Validation:</b> This invoice is currently being validated and is locked for changes. It will unlock and you will recieve a notification when the validation is complete.
										</p>
									</Box>
								)}
								<Grid container>
									<Grid item sm={9}>
										<Box>
											<h3>
												{`IEP INVOICE - #${invoice.invoice_num} - ${formatDateNoTimezoneMMDDYYYY(invoice.documented_dt)} -${invoice.iep_name}`}
											</h3>
										</Box>
									</Grid>
									<Grid item sm={3} style={{ whiteSpace: "nowrap" }}>
										<div style={{ float: "right" }}>
											<Button
												className={classes.button}
												size="small"
												disabled={
													// revalidation is disabled for all completed invoices, even those that have been unlocked, and invoices that are in validation
													(invoice.is_completed || invoice.in_validation)
												}
												variant="outlined"
												color="primary"
												onClick={handleReValidate}>
												ReValidate
											</Button>
											<Button
												className={classes.button}
												size="small"
												disabled={
													// this button is only enabled for competed invoices that have not already been unlocked
													// it is always disabled if the invoice is in validation
													!(invoice.is_completed && !invoice.ignore_update_dt) || invoice.in_validation
												}
												variant="outlined"
												color="primary"
												onClick={handleUnlock}>
												CLERICAL CHANGE
											</Button>
											<Button
												className={classes.button}
												size="small"
												// the review button is disabled if the invoice is in validation or there are any records in the needs review tab
												// TODO: ask the business to find a better name for this button, like "Finalize" or "Complete" or "Final Review"
												// disabling the 'Review' button because there are records that 'Need Review' is confusing
												disabled={invoice.in_validation || !!pendingFilteredRecs.length}
												variant="contained"
												color="primary"
												onClick={handleReviewClick}>
												Review
											</Button>
										</div>
									</Grid>
								</Grid>
							</Grid>
							<Grid item sm={12}>
								<Tabs value={selectedTab} onChange={handleChange}>
									<Tab
										label={`Needs Review (${pendingFilteredRecs.length})`}
										{...a11yTabNames(0)}
									/>
									<Tab label={`Approved (${approvedFilteredRecs.length})`} {...a11yTabNames(1)} />
									<Tab label={`To Dispute (${disputedRecs.length})`} {...a11yTabNames(2)} />
									<Tab label={`Credited (${creditedRecs.length})`} {...a11yTabNames(3)} />
								</Tabs>
								<Box>
									<TabPanel value={selectedTab} index={tabIndexes.REVIEW}>
										<InvoiceNeedsReview
											viewTrinium={true}
											viewReason={false}
											viewReview={true}
											viewPopOver={true}
											invoiceData={pendingFilteredRecs ? pendingFilteredRecs : []}
											statusData={statusList ? statusList : []}
											disputeReasonData={disputeReasons ? disputeReasons : []}
											PopoverControl={popoverInvoiceRecordApprovalDetails}
											maxHeight={600}
											disableControls={disableChanges}
											disableDropdown={false}
											searchRecordId={searchRecordId}
										/>
									</TabPanel>
									<TabPanel value={selectedTab} index={tabIndexes.APPROVED}>
										<InvoiceNeedsReview
											viewTrinium={true}
											viewReason={false}
											viewReview={true}
											viewPopOver={true}
											invoiceData={approvedFilteredRecs ? approvedFilteredRecs : []}
											statusData={statusList ? statusList : []}
											disputeReasonData={disputeReasons ? disputeReasons : []}
											PopoverControl={popoverInvoiceRecordApprovalDetails}
											maxHeight={600}
											disableControls={disableChanges}
											disableDropdown={false}
											searchRecordId={searchRecordId}
										/>
									</TabPanel>
									<TabPanel value={selectedTab} index={tabIndexes.DISPUTED}>
										<InvoiceNeedsReview
											viewTrinium={true}
											viewReason={true}
											viewReview={true}
											viewPopOver={true}
											invoiceData={disputedFilteredRecs || []}
											statusData={statusList || []}
											disputeReasonData={disputeReasons || []}
											PopoverControl={popoverInvoiceRecordApprovalDetails}
											maxHeight={600}
											disableControls={disableChanges}
											disableDropdown={false}
											searchRecordId={searchRecordId}
										/>
									</TabPanel>
									<TabPanel value={selectedTab} index={tabIndexes.CREDITED}>
										<InvoiceNeedsReview
											viewTrinium={true}
											viewReason={true}
											viewReview={true}
											viewPopOver={true}
											invoiceData={creditedRecs || []}
											statusData={statusList || []}
											disputeReasonData={disputeReasons || []}
											PopoverControl={popoverInvoiceRecordApprovalDetails}
											maxHeight={600}
											disableControls={disableChanges}
											disableDropdown={false}
											searchRecordId={searchRecordId}
										/>
									</TabPanel>
								</Box>
							</Grid>
						</Grid>
					</Box>
				) : (
					"Invoice not found"
				)}
			</Paper>
		</Layout>
	)
}
