import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import React from "react"
import { getModuleAlias, getSsl, newModule, updateModule, updatedAddAlias } from "../api"
import { isEmailValid } from "../lib/util"
import WaitPopup from "./WaitPopup"

interface Props {
	id: number
	handleClose: Function
	newState: boolean
}

export default function (props: Props) {
	const [ssl, setSsl] = React.useState<any | undefined>()
	const [name, setName] = React.useState("")
	const [abbrv, setAbbrv] = React.useState("")
	const [email, setEmail] = React.useState("")
	const [glCode, setGlCode] = React.useState("")

	let openWait = true

	React.useEffect(() => {
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (ssl) {
			setName(ssl.name)
			setEmail(ssl.contact_email)
			setAbbrv(ssl.abbrv)
		}
	}, [ssl])


	function loadPageInfo() {
		if (props.newState) {
			setSsl({ name: "", contact_email: "", abbrv: "" })
		} else {
			getSsl(props.id, setSsl)
			getModuleAlias(props.id, (data) => {
				if (data) {
					setGlCode(data.alias)
				}
			})
		}
	}

	function handleSave() {
		if (isSslValid()) {
			const alias = { moduleID: -1, glCode: "" }
			ssl.name = name
			ssl.contact_email = email || ""
			ssl.abbrv = abbrv
			ssl.module_type = "ssl"
			alias.glCode = glCode
			alias.moduleID = props.id

			if (props.newState) {
				newModule(ssl).then((data) => {
					alias.moduleID = data.module_id
					updatedAddAlias(alias, props.handleClose)
				})
			} else {
				updateModule(ssl).then((data) => {
					updatedAddAlias(alias, props.handleClose)
				})
			}
		} else {
			alert("Information is invalid or in the wrong format.")
		}
	}

	// todo: make sure name is not an empty or whitespace only string
	//			 email _looks_ required, but isn't actually required?
	function isSslValid() {
		if (name) {
			if (email) {
				return isEmailValid(email)
			}
			return true
		}
		return false
	}

	let emailError = false
	if (isEmailValid(email)) {
		emailError = false
	} else {
		emailError = true
	}

	if (ssl) {
		openWait = false
	}

	return (
		<div>
			<WaitPopup open={openWait}>{"...Loading Info"} </WaitPopup>
			<Grid container>
				<Grid item sm={12}>
					<Box>{props.newState ? <h3>Add SSL</h3> : <h3>Edit SSL</h3>}</Box>
				</Grid>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="NAME"
								value={name}
								onChange={(event: any) => {
									setName(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="ABBREVIATION"
								value={abbrv}
								onChange={(event: any) => {
									setAbbrv(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								error={emailError ? true : false}
								label="EMAIL"
								type="email"
								value={email}
								onChange={(event: any) => {
									setEmail(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="ALIAS"
								value={glCode}
								onChange={(event: any) => {
									setGlCode(event.target.value)
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={12}>
							<Box style={{ float: "right" }}>
								<Button
									style={{ margin: 5 }}
									onClick={() => {
										props.handleClose()
									}}
									variant="outlined"
									color="primary">
									Close
								</Button>
								<Button
									style={{ margin: 5 }}
									onClick={handleSave}
									variant="outlined"
									color="primary">
									Save
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
