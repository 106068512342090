import Box from "@material-ui/core/Box"
import Drawer from "@material-ui/core/Drawer"
import clsx from "clsx"
import React from "react"
import LocalStorageManager from "../../stores/LocalStorageManager"
import AppBarContent from "./AppBar"
import { layoutStyle } from "./LayoutStyle"
import SideMenu from "./SideMenu"

const savedDrawerState = LocalStorageManager<boolean>("drawer", false)

type Props = {}

export default function (props: React.PropsWithChildren<Props>) {
	const classes = layoutStyle()
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(savedDrawerState.get())

	React.useEffect(() => {
		//manage localstorage
		savedDrawerState.set(isDrawerOpen)
	}, [isDrawerOpen])

	return (
		<div className={classes.root}>
			<Drawer
				variant="permanent"
				open={isDrawerOpen}
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: isDrawerOpen,
					[classes.drawerClose]: !isDrawerOpen,
				})}>
				<SideMenu open={isDrawerOpen} setOpen={setIsDrawerOpen} />
			</Drawer>
			<main className={classes.main}>
				<AppBarContent />
				<Box className={classes.content}>
					<Box marginBottom={3}>{props.children}</Box>
				</Box>
			</main>
		</div>
	)
}
