import { format as formatDate } from "date-fns"

export function formatNumber(num: number): string {
	if (typeof num === "number" && num === num) {
		return num
			.toFixed(2)
			.replace(/\.00$/, "")
			.replace(/\.(\d)0$/, ".$1")
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}
	return num + ""
}

export function formatCurrency(num: number | undefined): string {
	if (num === null || num === undefined) {
		return ""
	}
	if (typeof num === "number" && num === num) {
		return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
	}
	return num + ""
}

// many of the dates in the database are stored as local time strings with no timezone
// this is the function to use when you know the date in the database is a local time string
// the deprecated function is still being used where we don't know the timezone
export function formatDateNoTimezoneMMDDYYYY(date: Date | string, withDashes?: boolean) {
	if (!date) {
		return ""
	}
	let newDate
	if (typeof date === "string") {
		newDate = new Date(date)
	} else {
		newDate = date
	}

	const withoutTimezone = new Date(newDate.valueOf()).toISOString().slice(0, -1)

	newDate = new Date(withoutTimezone)

	if (withDashes) {
		return `${newDate.getMonth() + 1}-${newDate.getDate()}-${newDate.getFullYear()}`
	}

	return `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`
}

/**
 * @deprecated Properly convert the date from the back end, then use `newFormatDateMMDDYYYY()` or `formatDateNoTimezoneMMDDYYYY()` instead.
 */
export function formatDateMMDDYYYY(date: Date, withDashes?: boolean) {
	if (date && typeof date === "string") {
		date = new Date(date)
	}

	const withoutTimezone = new Date(date.valueOf()).toISOString().slice(0, -1)

	date = new Date(withoutTimezone)

	if (withDashes) {
		return date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear()
	}

	return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
}


export function newFormatDateMMDDYYYY(date: Date | string, withDashes?: boolean) {
	if (!date) {
		return ""
	}
	let newDate
	if (typeof date === "string") {
		newDate = new Date(date)
	} else {
		newDate = date
	}

	if (withDashes) {
		return formatDate(newDate, "MM-DD-yyyy")
	}
	return formatDate(newDate, "MM/dd/yyyy")
}

export function formatDateMMDDYYYYAndTime(date: Date) {
	return formatDate(date, "MM/dd/yyyy hh:mm a")
}

export function formatPascalToString(text: string) {
	var wordList = []
	var phrase = ""
	if (text) {
		wordList = text.match(/[A-Z][a-z]+/g)
		wordList.forEach((word) => {
			phrase += " " + word
		})
	}
	return phrase
}


