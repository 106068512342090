import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import React from "react"
import {
	deleteInvoice,
	getInvoices,
	getSettings,
} from "../api"
import RecSum from "../components/DashboardRecordSummary"
import InvoiceRecons from "../components/InvoiceRecons"
import WaitPopup from "../components/WaitPopup"
import Layout from "../components/layout/Layout"
import type { InvoiceDTO } from "../types/Invoice"
import type { SettingDTO } from "../types/tables/Setting"

type FilterButtonType = "all" | "complete" | "incomplete" | "urgent"

export default function DashboardPage() {
	const [completeInvoices, setcompleteInvoices] = React.useState<InvoiceDTO[]>()
	const [incompleteInvoices, setincompleteInvoices] = React.useState<InvoiceDTO[]>()
	const [urgentInvoices, seturgentInvoices] = React.useState<InvoiceDTO[]>()

	const [invoices, setInvoices] = React.useState<InvoiceDTO[]>()
	const [filterBtn, setFilterBtn] = React.useState<FilterButtonType>("incomplete")
	const [IEPFilter, setIEPFilter] = React.useState<number[]>([])

	const selectedInvoiceIDRef = React.useRef<number>(null)

	const [settings, setSettings] = React.useState<SettingDTO[]>()
	const [openWait, setOpenWait] = React.useState<boolean>(false)
	const [openInvoiceDeleteConfirm, setOpenInvoiceDeleteConfirm] = React.useState<boolean>(false)

	React.useEffect(() => {
		if (localStorage.getItem("dashboardfilter")) {
			const iepFilters = localStorage.getItem("dashboardfilter").split(",")
			const iepFilterList: number[] = []
			for (const item of iepFilters) {
				iepFilterList.push(Number.parseInt(item))
			}
			setIEPFilter(iepFilterList)
		}
		loadPageInfo()
	}, [])

	React.useEffect(() => {
		if (invoices?.length) {
			for (const element of invoices) {
				element.iep_name = element.iep_name ? element.iep_name.toUpperCase() : ""
				element.name = element.name ? element.name.toUpperCase() : ""
				element.user = element.user ? element.user.toUpperCase() : ""
				element.documented_dt = new Date(element.documented_dt)
			}
			setcompleteInvoices(invoices.filter((inv: InvoiceDTO) => inv.is_completed === true))
			setincompleteInvoices(
				invoices.filter((inv: InvoiceDTO) => inv.is_completed === false || inv.is_completed === null),
			)
		}
	}, [invoices])

	React.useEffect(() => {
		const urgentInvoicesList: InvoiceDTO[] = []
		if (incompleteInvoices && settings) {
			const daysBeforeDueDate = settings.find((setting) => {
				return setting.name === "DaysForInvoiceDueDate"
			}).value
			const daysBeforeUrgent = settings.find((setting) => {
				return setting.name === "DaysBeforeInvoiceIsUrgent"
			}).value
			const daysBeforeDueDateInt = daysBeforeDueDate ? Number.parseInt(daysBeforeDueDate) : 0
			const daysBeforeUrgentInt = daysBeforeUrgent ? Number.parseInt(daysBeforeUrgent) : 0
			const today = new Date()

			for (const ele of incompleteInvoices) {
				const invoiceDt = new Date(ele.documented_dt)
				invoiceDt.setDate(invoiceDt.getDate() + (daysBeforeDueDateInt - daysBeforeUrgentInt))
				if (today >= invoiceDt) {
					ele.isUrgent = true
					urgentInvoicesList.push(ele)
				}
			}
			seturgentInvoices(urgentInvoicesList)
		}
	}, [incompleteInvoices, settings])

	React.useEffect(() => {
		localStorage.setItem("dashboardfilter", IEPFilter.toString())
	}, [IEPFilter])

	let displayInv: InvoiceDTO[] = []

	switch (filterBtn) {
		case "all":
			displayInv = invoices
			break
		case "complete":
			displayInv = completeInvoices
			break
		case "incomplete":
			displayInv = incompleteInvoices
			break
		case "urgent":
			displayInv = urgentInvoices
			break
	}

	if (IEPFilter?.length && displayInv) {
		displayInv = displayInv.filter((inv) => {
			return IEPFilter.includes(inv.iep_id)
		})
	}

	React.useEffect(() => {
		if (invoices) {
			setOpenWait(false)
		}
	}, [invoices])

	const loadPageInfo = () => {

		getInvoices(setInvoices)

		if (!settings) {
			getSettings(setSettings)
		}
	}

	// todo: let's see if we can move this function all the way down to
	// 		   InvoiceReconcilliations.tsx where it's actually used
	const handleDeleteClickCached = React.useCallback((itemID: number) => {
		selectedInvoiceIDRef.current = itemID
		setOpenInvoiceDeleteConfirm(true)
	}, [])

	const handleInvoiceDelete = () => {
		const invoiceID = selectedInvoiceIDRef.current
		setOpenWait(true)
		setOpenInvoiceDeleteConfirm(false)
		deleteInvoice(invoiceID, (_data) => {
			selectedInvoiceIDRef.current = undefined
			setOpenWait(false)
			loadPageInfo()
		})
	}

	const handleClose = () => {
		setOpenInvoiceDeleteConfirm(false)
		selectedInvoiceIDRef.current = undefined
	}

	if (displayInv && settings) {
		const daysBeforeDueDate: number = Number.parseInt(
			settings.find((setting) => {
				return setting.name === "DaysForInvoiceDueDate"
			}).value,
		)

		for (const ele of displayInv) {
			if (!ele.is_completed) {
				// TODO need to use a date library to calculate the due date of Javascript Date object
				const invoiceDt = new Date(ele.documented_dt)
				invoiceDt.setDate(invoiceDt.getDate() + daysBeforeDueDate)
				ele.dueDt = invoiceDt
			}
		}
	}

	const totalInvNum: number = displayInv ? displayInv.length : 0
	const completeInvNum: number = invoices ? invoices.filter((inv: InvoiceDTO) => inv.is_completed === true).length : 0
	const incompleteInvNum: number = invoices
		? invoices.filter((inv: InvoiceDTO) => inv.is_completed === false || inv.is_completed === null).length
		: 0
	const urgentInvNum: number = urgentInvoices ? urgentInvoices.length : 0

	return (
		<Layout>
			<Box style={{ margin: 5, width: "100%" }}>
				<WaitPopup open={openWait}>{"...Loading Info"}</WaitPopup>
				<Grid container>
					<Grid item sm={12}>
						<Grid container style={{ marginRight: 5 }}>
							<Grid item sm={12} style={{ marginBottom: 10 }}>
								<Paper style={{ padding: 10 }}>
									<RecSum
										setSelectedFilter={setFilterBtn}

										totalInvoices={totalInvNum}
										completeInvoices={completeInvNum}
										incompleteInvoices={incompleteInvNum}
										urgentInvoices={urgentInvNum}
										handleSetIEPFilter={setIEPFilter}
									/>
								</Paper>
							</Grid>
							<Grid item sm={12}>
								<Paper style={{ maxHeight: "69vh", overflow: "auto" }}>
									<InvoiceRecons
										invoiceData={displayInv || []}
										handleDeleteClick={handleDeleteClickCached}
									/>
									<Dialog
										open={openInvoiceDeleteConfirm}
										onClose={handleClose}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description">
										<DialogTitle id="alert-dialog-title">
											{"Are you sure you want to Delete this item?"}
										</DialogTitle>
										<DialogActions>
											<Button onClick={handleClose} color="primary">
												No, keep it.
											</Button>
											<Button onClick={handleInvoiceDelete} color="primary">
												Yes, delete.
											</Button>
										</DialogActions>
									</Dialog>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Layout>
	)
}
