import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { type ChangeEvent, type FC, useEffect, useState } from "react"
import { addUpdateTerminal, getTerminal } from "../api"
import { isEmailValid } from "../lib/util"
import type { Alias } from "../types/Alias"
import type { TerminalModule } from "../types/TerminalModule"
import WaitPopup from "./WaitPopup"

export const EditTerminal: FC<{ terminalId: number; handleClose: () => void }> = ({
	terminalId,
	handleClose,
}) => {
	const [terminal, setTerminal] = useState<TerminalModule | undefined>()

	useEffect(() => {
		loadPageInfo()
	}, [])

	async function loadPageInfo() {
		if (terminalId === -1) {
			setTerminal({
				id_: -1,
				name: "",
				abbrv: "",
				contact_email: "",
				module_type: "terminal",
				aliases: [],
			})
		} else {
			await getTerminal(terminalId).then((data) => {
				setTerminal(data)
			})
		}
	}
	const handleGlCodeChange = (glCode: string, index: number, is_recoverable: boolean) => {
		const alias = terminal?.aliases[index]
		const changedGLCode = glCode?.trim()
		if (alias) {
			alias.alias = changedGLCode // this works because alias is a reference object that points to the original object
		} else {
			const newAlias: Alias = {
				id_: -1,
				module_id: terminal.id_,
				alias: changedGLCode,
				alias_metadata: {
					// this will need to change when we support more than just recoverable and unrecoverable.
					Name: is_recoverable ? "Recoverable" : "Unrecoverable",
					Description: is_recoverable ? "Recoverable" : "Unrecoverable",
					is_recoverable: is_recoverable,
					module_type: "terminal",
				},
			}
			terminal.aliases.push(newAlias)
		}
		setTerminal({ ...terminal })
	}

	async function handleSave() {
		if (isEmailValid(terminal?.contact_email || "")) {
			await addUpdateTerminal(terminal).then((_) => handleClose())
		} else {
			// This should never get hit, but is here just in case.
			alert("Email is not valid.")
		}
	}

	return (
		<div>
			<WaitPopup open={!terminal}>{"...Loading Info"} </WaitPopup>
			<Grid container>
				<Grid item sm={12}>
					<Box>{terminalId === -1 ? <h3>Add Terminal</h3> : <h3>Edit Terminal</h3>}</Box>
				</Grid>
				<Grid item sm={12}>
					<Grid container>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="NAME"
								value={terminal?.name || ""}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									setTerminal({ ...terminal, name: event.target.value })
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="ABBREVIATION"
								value={terminal?.abbrv || ""}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									setTerminal({ ...terminal, abbrv: event.target.value })
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								error={!isEmailValid(terminal?.contact_email || "")}
								label="EMAIL"
								type="email"
								value={terminal?.contact_email || ""}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									setTerminal({ ...terminal, contact_email: event.target.value })
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="Recoverable GL CODE"
								value={terminal?.aliases.find((a) => a.alias_metadata.is_recoverable)?.alias || ""}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									const index = terminal?.aliases.findIndex((a) => a.alias_metadata.is_recoverable)
									handleGlCodeChange(event.target.value, index, true)
								}}
								variant="outlined"
							/>
						</Grid>
						{/* Spacer so the GL Codes are aligned */}
						{/* <Grid item sm={5} style={{ margin: 10 }} /> 
						todo: Commented out until https://worldshipping.atlassian.net/browse/WHEELZ3-78 is ready to test
						<Grid item sm={5} style={{ margin: 10 }}>
							<TextField
								label="Unrecoverable GL CODE"
								value={terminal?.aliases.find((a) => !a.alias_metadata.is_recoverable)?.alias || ""}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									const index = terminal?.aliases.findIndex((a) => !a.alias_metadata.is_recoverable)
									handleGlCodeChange(event.target.value, index, false)
								}}
								variant="outlined"
							/>
						</Grid> */}
						<Grid item sm={12}>
							<Box style={{ float: "right" }}>
								<Button
									style={{ margin: 5 }}
									onClick={() => {
										handleClose()
									}}
									variant="outlined"
									color="primary">
									Close
								</Button>
								<Button
									style={{ margin: 5 }}
									onClick={handleSave}
									variant="outlined"
									color="primary"
									disabled={!isEmailValid(terminal?.contact_email || "")}>
									Save
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
