import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { useEffect, useState, type ChangeEvent } from "react"
import { Link, useHistory } from "react-router-dom"
import {
	getDisputeReasons,
	getInvoice,
	getInvoiceRecords,
	markInvoicesAsCompleted
} from "../api"
import InvoiceNeedsReview from "../components/CustomTable/invoiceWorkflowTable/InvoiceNeedsReview"
import { TabPanel } from "../components/TabPanel"
import WaitPopup from "../components/WaitPopup"
import Layout from "../components/layout/Layout"
import { a11yTabNames } from "../lib/a11y"
import { authStore } from "../stores/authStore"
import { toastStore } from "../stores/toastStore"
// import { downloadFile } from "../lib/util"
// import {downloadDisputeForm } from "../api"


function InvoiceReviewWorkflow(props: any) {
	const [tabNumber, setTabNumber] = useState(0)
	const [approved, setApproved] = useState<any | undefined>()
	const [disputed, setDisputed] = useState<any | undefined>()
	const [merged, setMerged] = useState<any | undefined>()
	const [invoice, setInvoice] = useState<any | undefined>()
	const [disputeReasons, setDisputeReasons] = useState<any | undefined>()
	const [waitMsg, setWaitMsg] = useState<string | undefined>("...Loading Info")
	const [openWait, setOpenWait] = useState<boolean>(true)

	const invoiceId = props.match.params.id
	const auth = authStore.use()
	const me = auth.me
	const history = useHistory()

	const tabIndexes = {
		APPROVED: 0,
		DISPUTED: 1,
		MERGED: 2,
	}


	useEffect(() => {
		loadPageInfo()
	}, [])

	useEffect(() => {
		if (approved && disputed && merged) {
			setOpenWait(false)
		}
	}, [approved, disputed, merged])

	const loadPageInfo = () => {
		if (!invoice) {
			getInvoice(invoiceId, setInvoice)
		}
		if (!disputeReasons) {
			getDisputeReasons(setDisputeReasons)
		}
		getInvoiceRecords(invoiceId, setApproved, "approved")
		getInvoiceRecords(invoiceId, setDisputed, "disputed")
		getInvoiceRecords(invoiceId, setMerged)
	}

	const handleFinishClick = async () => {
		setWaitMsg("...Marking Invoice as Complete")
		setOpenWait(true)

		// if you don't have a username you're probably not logged in and will be kicked back to the login page
		await markInvoicesAsCompleted(invoiceId, me?.username ?? "").then(() => {
			toastStore.showToast("success", `Invoice ${invoice?.invoice_num ?? ""} marked as Complete`)
			history.push("/dashboard")
		})
	}

	return (
		<Layout>
			<Paper style={{ margin: 5 }}>
				<WaitPopup open={openWait}>{waitMsg}</WaitPopup>
				<Box style={{ padding: 30 }}>
					<Grid container style={{ overflow: "hidden", width: "100%" }}>
						<Grid item sm={12}>
							<Box>
								<h3>REVIEW - INVOICE #{invoice ? invoice.invoice_num : ""}</h3>
							</Box>
						</Grid>
						<Grid item sm={12}>
							<Tabs value={tabNumber} onChange={(_: ChangeEvent<{}>, val: number) => {
								setTabNumber(val)
							}
							}>
								<Tab
									label={"Aproved (" + (approved ? approved.length : 0) + ")"}
									{...a11yTabNames(0)}
								/>
								<Tab
									label={"Disputed (" + (disputed ? disputed.length : 0) + ")"}
									{...a11yTabNames(1)}
								/>
								<Tab label={"Merged (" + (merged ? merged.length : 0) + ")"} {...a11yTabNames(2)} />
							</Tabs>
							<Box>
								<TabPanel value={tabNumber} index={tabIndexes.APPROVED}>
									<InvoiceNeedsReview
										viewTrinium={false}
										viewReason={false}
										viewReview={false}
										viewPopOver={false}
										invoiceData={approved ? approved : []}
										statusData={[]}
										disputeReasonData={disputeReasons ? disputeReasons : []}
										disableControls={true}
										maxHeight={300}
									/>
								</TabPanel>
								<TabPanel value={tabNumber} index={tabIndexes.DISPUTED}>
									<InvoiceNeedsReview
										viewTrinium={false}
										viewReason={true}
										viewReview={false}
										viewPopOver={false}
										invoiceData={disputed ? disputed : []}
										statusData={[]}
										disputeReasonData={disputeReasons ? disputeReasons : []}
										disableControls={true}
										maxHeight={300}
									/>
								</TabPanel>
								<TabPanel value={tabNumber} index={tabIndexes.MERGED}>
									<InvoiceNeedsReview
										viewTrinium={false}
										viewReason={true}
										viewReview={false}
										viewPopOver={false}
										invoiceData={merged ? merged : []}
										statusData={[]}
										disputeReasonData={disputeReasons ? disputeReasons : []}
										disableControls={true}
										maxHeight={300}
										displayAllItems={true}
									/>
								</TabPanel>
							</Box>
						</Grid>
						<Grid item sm={12}>
							<Grid container>
								<Grid item sm={12}></Grid>
								<Grid item sm={12}>
									<Button
										variant="contained"
										color="primary"
										style={{ margin: 10, float: "right" }}
										onClick={handleFinishClick}>
										Finish
									</Button>
									<Link to={"/invoiceworkflow/" + (invoice ? invoice.id_ : -1)}>
										<Button
											variant="outlined"
											color="primary"
											style={{ margin: 10, float: "right" }}>
											Back
										</Button>
									</Link>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</Layout>
	)
}

export default InvoiceReviewWorkflow