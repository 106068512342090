import {
	Box,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import WarningIcon from "@material-ui/icons/Warning"
import React from "react"
import { Link } from "react-router-dom"

import { formatDateNoTimezoneMMDDYYYY } from "../../../lib/formatters"
import { type Order, getComparator, stableSort } from "../../../lib/sort"
import FilterDialog from "../../FilterDialogAP"
import TableToExcel from "../../TableToExcel/TableToExcel"
import { useStyles } from "./styles"
import { type DataInvoiceRecons, type HeadCell, HeaderDataInvoiceRecons } from "./types"

interface EnhancedTableProps {
	classes: ReturnType<typeof useStyles>
	numSelected: number
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DataInvoiceRecons) => void
	order: Order
	orderBy: string
	rowCount: number
	headCells: HeadCell[]
	canDelete?: boolean
}

function SortableTableHeaders(props: EnhancedTableProps) {
	const { classes, order, orderBy, onRequestSort, headCells } = props
	const createSortHandler =
		(property: keyof DataInvoiceRecons) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property)
		}
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.numeric ? "right" : "left"}
							sortDirection={orderBy === headCell.id ? order : false}
							padding={"checkbox"}
							style={{
								backgroundColor: "rgba(4, 101, 170, 1)",
								color: "white",
							}}>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
								hideSortIcon={true}>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === "desc" ? "sorted descending" : "sorted ascending"}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					)
				})}
				{props.canDelete && (
					<TableCell
						style={{
							width: 10,
							backgroundColor: "rgba(4, 101, 170, 1)",
							color: "white",
						}}>
						...
					</TableCell>
				)}
			</TableRow>
		</TableHead>
	)
}

interface Props {
	invoiceData: any[]
	handleDeleteClick?: Function
	maxHeight?: number
}

export default function (props: Props) {
	const classes = useStyles()
	const [order, setOrder] = React.useState<Order>("asc")
	const [orderBy, setOrderBy] = React.useState<string>("id_")
	const [selected, setSelected] = React.useState<string[]>([])
	const [filtersOpen, setFiltersOpen] = React.useState(false)
	const [filteredRecords, setFilteredRecords] = React.useState<any[]>([])
	const [unfilteredRecords, setUnfilteredRecords] = React.useState<any[]>([])

	React.useEffect(() => {
		if (props.invoiceData) {
			setUnfilteredRecords(props.invoiceData)
			setFilteredRecords(props.invoiceData)
		}
	}, [props.invoiceData])

	const handleRequestSort = (
		_event: React.MouseEvent<unknown>,
		property: keyof DataInvoiceRecons,
	) => {
		const isAsc = orderBy === property && order === "asc"
		setOrder(isAsc ? "desc" : "asc")
		setOrderBy(property)
	}

	const handleClick = (_event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name)
		let newSelected: string[] = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			)
		}

		setSelected(newSelected)
	}

	const isSelected = (name: string) => selected.indexOf(name) !== -1

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<FilterDialog
				open={filtersOpen}
				onClose={setFiltersOpen}
				unfilteredRecords={unfilteredRecords}
				setFilteredRecords={setFilteredRecords}
			/>
			<Box width="100%">
				<TableToExcel
					table="InvoiceRec"
					fileName={"InvoiceRec_" + Date.now()}
					sheet="test1"
					buttonText="export"
					id="button-download-as-xls"
					className="button-download"
				/>
			</Box>

			<TableContainer style={{ width: "100%" }}>
				<Table id="InvoiceRec" stickyHeader={true} size={"small"}>
					<SortableTableHeaders
						classes={classes}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={filteredRecords.length}
						headCells={HeaderDataInvoiceRecons}
						canDelete={props.handleDeleteClick ? true : false}
					/>
					<TableBody>
						{stableSort(filteredRecords, getComparator(order, orderBy)).map((row, _index) => {
							const isItemSelected = isSelected(row.id_)
							return (
								<TableRow
									key={row.id_}
									hover
									onClick={(event) => handleClick(event, row.id_)}
									tabIndex={-1}
									selected={isItemSelected}>
									<TableCell>
										<Link to={"/invoiceworkflow/" + row.id_}>{row.invoice_num}</Link>
									</TableCell>
									<TableCell>
										{row.dueDt ? formatDateNoTimezoneMMDDYYYY(row.dueDt) : "COMPLETE"}
										{row.isUrgent && <WarningIcon style={{ color: "red" }} />}
									</TableCell>
									<TableCell>{row.iep_name}</TableCell>
									<TableCell>{row.name}</TableCell>
									<TableCell>{row.user}</TableCell>
									<TableCell>
										{row.documented_dt ? formatDateNoTimezoneMMDDYYYY(row.documented_dt) : ""}
									</TableCell>
									{props.handleDeleteClick && (
										<TableCell>
											<IconButton
												aria-label="delete"
												size="small"
												onClick={() => {
													props.handleDeleteClick(row.id_)
												}}>
												<DeleteIcon fontSize="inherit" />
											</IconButton>
										</TableCell>
									)}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}
