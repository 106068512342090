import { Box, Typography } from "@material-ui/core"

export function TabPanel({ children, index, value }: { children?: React.ReactNode, index: any, value: any }) {
  // const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    // {...other}
    >
      {value === index && <Box paddingTop={0}>{children}</Box>}
    </Typography>
  )
}